<template>
  <ClientOnly>
    <nav
      class="bg-background/80 fixed left-0 top-0 z-20 w-full backdrop-blur-lg transition-[height] duration-200"
      :class="[isTop ? 'shadow-none' : 'shadow-sm']"
    >
      <!-- <MarketingBanner /> -->

      <div class="flex justify-between items-center w-full px-8">
        <!-- Logo Section -->
        <!-- <div class="flex justify-start mt-4 lg:mt-0">
          <NuxtLinkLocale
            to="/"
            class="block hover:no-underline active:no-underline"
          >
            <Logo
            />
          </NuxtLinkLocale>
        </div> -->

        <!-- Navigation Section - Centered -->
        <div
          class="flex-grow flex items-center justify-center gap-6 transition-all duration-200"
          :class="[isTop ? 'py-8' : 'py-8 sm:py-4']"
        >
          <div class="hidden lg:flex items-center justify-center sm:space-x-12">
            <NuxtLinkLocale
              v-for="menuItem of menuItems"
              :key="menuItem.to"
              :to="menuItem.to"
              class="block px-3 py-2 text-sm sm:text-lg text-primary hover:text-primary/80 custom-hover-effect w-full font-semibold whitespace-nowrap"
            >
              {{ menuItem.label }}
            </NuxtLinkLocale>
          </div>
        </div>

        <!-- Right Section for Button -->
        <div class="flex justify-end">
          <div class="flex items-center">
            <ColorModeToggle />
            <LocaleSwitch />
          </div>
          <SheetRoot v-model:open="mobileMenuOpen">
            <SheetTrigger as-child>
              <Button class="lg:hidden" size="icon" variant="outline">
                <Icon name="menu" />
              </Button>
            </SheetTrigger>
            <SheetContent class="w-[250px]" side="right">
              <VisuallyHidden>
                <DialogTitle>Menu</DialogTitle>
                <DialogDescription>Navigation Menu</DialogDescription>
              </VisuallyHidden>

              <div class="flex flex-col items-start justify-center">
                <NuxtLinkLocale
                  v-for="menuItem of menuItems"
                  :key="menuItem.to"
                  :to="menuItem.to"
                  class="block px-3 py-2 text-lg"
                >
                  {{ menuItem.label }}
                </NuxtLinkLocale>

                <NuxtLinkLocale
                  :to="
                    hasUser ? runtimeConfig.auth.redirectPath : '/auth/login'
                  "
                  :prefetch="!hasUser"
                  class="block px-3 py-2 text-lg"
                >
                  {{
                    hasUser
                      ? t("common.menu.dashboard")
                      : t("common.menu.login")
                  }}
                </NuxtLinkLocale>
              </div>
            </SheetContent>
          </SheetRoot>

          <div class="hidden lg:flex items-center justify-center space-x-4">
            <NuxtLinkLocale
              :to="
                hasUser
                  ? user?.isSubscribed
                    ? runtimeConfig.auth.redirectPath
                    : '/prospere/settings/account/general'
                  : '/auth/login'
              "
              :prefetch="!hasUser"
              class="px-3 py-2 text-lg text-primary hover:text-primary/80 custom-hover-effect font-semibold whitespace-nowrap"
            >
              {{
                hasUser ? t("common.menu.dashboard") : t("common.menu.login")
              }}
            </NuxtLinkLocale>

            <Button class="hidden md:block" as-child variant="default">
              <NuxtLinkLocale
                :to="hasUser ? runtimeConfig.auth.redirectPath : '/auth/login'"
                :prefetch="!hasUser"
                class="px-3 py-2 text-lg whitespace-nowrap"
              >
                <!-- Ask for a demo -->
                {{ t("common.menu.demo") }}
              </NuxtLinkLocale>
            </Button>
          </div>
        </div>
      </div>
    </nav>
  </ClientOnly>
</template>

<script setup lang="ts">
import { useWindowScroll } from "@vueuse/core";
import { VisuallyHidden } from "radix-vue";

const route = useRoute();
const { t } = useTranslations();
const { y: verticalScrollPosition } = useWindowScroll();
const { user, loaded: userLoaded } = useUser();
const isTop = computed(() => verticalScrollPosition.value < 10);
const { public: runtimeConfig } = useRuntimeConfig();
const hasUser = computed(() => {
  return userLoaded.value && user.value;
});
const mobileMenuOpen = ref(false);

watch(
  () => route.fullPath,
  () => {
    mobileMenuOpen.value = false;
  }
);

type MenuItem = {
  label: string;
  to: string;
};
const menuItems = computed<MenuItem[]>(() => [
  {
    label: t("common.menu.about"),
    to: "/",
  },
  {
    label: t("common.menu.features"),
    to: "/features",
  },
  {
    label: t("common.menu.clients"),
    to: "/clients",
  },
  {
    label: t("common.menu.pricing"),
    to: "/pricing",
  },
]);
</script>
