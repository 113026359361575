<template>
  <footer class="bg-gray-800/85 text-muted-foreground py-12">
    <div class="container grid grid-cols-1 gap-6 lg:grid-cols-4 text-white">
      <div class="flex flex-col gap-2">
        <a href="#" class="block text-sm opacity-70">
          {{ t("footer.legalMentions") }}
        </a>
        <NuxtLinkLocale to="/" class="text-xl font-bold">
          {{ t("footer.about") }}
        </NuxtLinkLocale>
        <a href="#" class="block text-sm opacity-70">
          {{ t("footer.legalMentions") }}
        </a>
      </div>
      <div class="flex flex-col gap-2">
        <h1 class="text-lg font-bold">{{ t("footer.partners") }}</h1>
        <a href="#" class="block text-sm opacity-70">
          {{ t("footer.legalMentions") }}
        </a>
        <a href="#" class="block text-sm opacity-70">
          {{ t("footer.legalMentions") }}
        </a>
      </div>

      <div class="flex flex-col gap-2">
        <h1 class="text-lg font-bold">{{ t("footer.contact") }}</h1>
        <a href="#" class="block text-sm opacity-70">
          {{ t("footer.legalMentions") }}
        </a>
        <a href="#" class="block text-sm opacity-70">
          {{ t("footer.legalMentions") }}
        </a>
      </div>

      <div class="flex flex-col gap-2">
        <h1 class="text-lg font-bold">{{ t("footer.contact") }}</h1>
        <a href="#" class="block text-sm opacity-70">
          {{ t("footer.legalMentions") }}
        </a>
        <a href="#" class="block text-sm opacity-70">
          {{ t("footer.legalMentions") }}
        </a>
      </div>
    </div>
    <div class="flex justify-center mt-12">
      <p class="mt-3 text-sm opacity-70 text-white">
        {{ t("footer.allRightsReserved") }} ©PROSPERE.IO
      </p>
    </div>
  </footer>
</template>

<script setup lang="ts">
const { t } = useTranslations();
</script>
